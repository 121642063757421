<template>
  <div class="confirm-page">
    <div class="container">
      <img class="title" src="../assets/images/confirm.png" alt="Merci pour votre inscription"/>
      <p>A très vite pour recevoir votre French actu.</p>
      <a class="btn-cta btn-confirm" @click="exitUrl(1)" target="_blank">Retour à l'accueil</a>
      <img class="parallax" src="../assets/images/confirm_bg.png" :style="parallax(1.5)"/>
      <img class="bg" src="../assets/images/confirm_bg_mobile.png"/>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Bowser from 'bowser'

export default {
  name: 'Diffusion',
  data () {
    return {
      mouseY: 0,
      mouseX: 0
    }
  },
  methods: {
    ...mapActions(['updateModalConfig']),
    mousemove (event) {
      this.mouseX = (event.x - window.innerWidth / 2) * -1
      this.mouseY = (event.y - window.innerHeight / 2) * -1
    },
    parallax (speed) {
      return (
        'transform: translate3d(' +
        this.mouseX * 0.01 * speed +
        'px, ' +
        this.mouseY * 0.01 * speed +
        'px, 0);'
      )
    },
    exitUrl(linknumber) {
      let windowReference = window.open()
      // when window.open() is not supported
      if (windowReference === null) {
        windowReference = window
      }

      this.$requestAPI
        .trackPageClick({
          iPageId: this.$route.meta.pageId,
          iLinkNumber: linknumber
        })
        .then(response => {
          windowReference.location = response.data.data[0];
        });
    },
  },
  mounted () {
    this.$mmTro.sendRtg()
    const browser = Bowser.getParser(window.navigator.userAgent)
    if (!browser.isBrowser('ie', true) && !browser.isBrowser('edge', true)) {
      window.addEventListener('mousemove', this.mousemove)
    }
    /*eslint-disable*/
    tc_events_global(this, 'PAGELOAD', { 'service': 'inscription_newsletter', 'page_category': 'confirmation_inscription' })
    /*eslint-enable*/
  },
  computed: {
    ...mapGetters({
      decliConfig: "config/activeDecliConfig",
      user: "user/user",
      token: "global/token",
      idu: "user/idu",
      visitId: "global/visitId",
      appConfig: "config/appConfig",
    }),
    idfroms() {
      return this.decliConfig.idfroms;
    },
    linkNumbers() {
      return this.decliConfig.linkNumbers;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../styles/variables';

.confirm-page{
  background-color: white;
  .container{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 888px;
    height: 514px;
    overflow: hidden;
    padding: 2rem;
    box-sizing: border-box;
    flex-flow: column nowrap;
    justify-content: center;
    margin: auto;
    @media (min-width: 700px) {
      overflow: hidden;
    }
    @media #{$medium} {
      width: 100vw;
    }

    @media #{$small} {
      width: 100vw;
      height: 450px;
      justify-content: flex-start
    }

    img.title {
      max-width: 541px;
      max-height: 136px;
      margin-top: 100px;
      @media #{$medium} {
        transform: scale(0.8);
      }

      @media #{$small} {
        transform: scale(0.65);
        margin-top: 50px;
      }
    }

    p {
      color: $dark-green;
      font-size: 18px;
      @media #{$small} {
       font-size: 15px;
      }
    }

    a {
      margin-bottom: 60px;
      margin-top: auto;
      z-index: 999;
    }

    img.parallax {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 95%;
      margin: auto;
      transition: transform 100ms ease-out;
      @media #{$small} {
        display: none;

      }
    }

    img.bg {
      position: absolute;
      max-width: 397px;
      width: 100%;
      display: none;
      @media #{$small} {
        display: block;
        top: 25px;
        left: 10px;
      }
    }

  }
}

</style>
